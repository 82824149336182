<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog
        modal-dialog-centered
        modal-xl
        modal-fullscreen-lg-down
        modal-dialog-scrollable
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn btn-toolbar"
            :aria-label="$t(`cataloge.back`)"
            v-on:click="back()"
            v-if="editItem"
          >
            <BaseIcon name="left-arrow2" />
          </button>
          <h5 class="modal-title" id="staticBackdropLabel">
            {{ $t("manufacturingOperationsModal.manufacturingOperations") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
          ></button>
        </div>
        <div class="model-body" v-if="editItem">
          <div class="m-4">
            <h2 class="mb-2">{{ editItem.name }}</h2>
            <WYSIWYG v-model="editItem.description" />
          </div>
        </div>
        <div class="modal-body" ref="modalBody" v-else>
          <draggable
            class="dragArea row custom-input mx-md-5 px-md-5 pt-2 whitebg-input"
            tag="ul"
            :list="operationSteps"
            :group="{ name: 'g1' }"
            item-key="id"
            v-bind="dragOptions"
            :forceFallback="true"
            :scroll="true"
            :scrollSensitivity="100"
          >
            <template #item="{ element, index }">
              <li class="row">
                <div class="col-xl-1 col-2" v-if="index === 0"></div>
                <div class="col-xl-1 col-2" v-else>
                  <label class="form-label bold-12 text-gray overflow-hidden">{{
                    $t("manufacturingOperationsModal.parallel")
                  }}</label>
                  <label class="cstm-check mt-3 mb-4">
                    <input
                      type="checkbox"
                      :value="true"
                      v-model="element.parallel"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="col-xl-5 col-4">
                  <div class="mb-3">
                    <label class="form-label bold-12 text-gray">{{
                      $t(`manufacturingOperationsModal.step`, {
                        ordinal_suffix_of: ordinal_suffix_of(index),
                      })
                    }}</label>
                    <v-select
                      v-model="element.id"
                      taggable
                      :reduce="(e) => e.id"
                      :options="operations"
                      :create-option="(v) => createOption(v)"
                      v-on:option:selected="(e) => selectedItem(e)"
                      label="name"
                    />
                  </div>
                </div>
                <div class="col-1">
                  <label class="form-label bold-12 text-gray">{{
                    $t("manufacturingOperationsModal.more2")
                  }}</label>
                  <button
                    class="mt-2 btn btn-outline-primary"
                    v-on:click="editDescription(element)"
                  >
                    <BaseIcon name="edit" />
                  </button>
                </div>
                <div class="col-1">
                  <label class="form-label bold-12 text-gray">{{
                    $t("manufacturingOperationsModal.videos")
                  }}</label>
                  <button
                    class="mt-2 btn btn-outline-primary"
                    :title="$t(`manufacturingOperationsModal.manageVideos`)"
                    v-on:click="editVideos(element)"
                  >
                    <BaseIcon name="video" />
                  </button>
                </div>
                <div class="col-3">
                  <label class="form-label bold-12 text-gray">{{
                    $t("manufacturingOperationsModal.time")
                  }}</label>
                  <div class="fix-end-input">
                    <input
                      type="number"
                      v-model="element.time"
                      class="form-control"
                      min="1"
                    />
                    <span>{{ $t("manufacturingOperationsModal.sec") }}</span>
                  </div>
                </div>
              </li>
            </template>
          </draggable>
          <div class="row mt-4">
            <div class="col-6">
              <label class="cstm-check d-flex">
                <span class="regular-12 text-black mt-3">
                  {{ $t("ingredients.lock") }}
                </span>
                <input type="checkbox" v-model="lockData" :value="true" />
                <span class="checkmark mt-3"></span>
              </label>
            </div>
            <div class="col-6 text-end">
              <button
                class="btn btn-type-2"
                v-if="!editItem"
                v-on:click="save()"
              >
                <span>{{ $t("finance.save") }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Videos v-model:show="showVideo" :id="id" type="operation" />
  <ConfirmModal
    ref="createModal"
    :title="$t(`manufacturingOperationsModal.createOperation`)"
    :text="`<ul><li>You will create a new operation<b></li></ul><p>Are you sure you want to continue?</p>`"
    cancel-button="No, cancel"
    :ok-button="`Yes, create ${createObject?.name}`"
    v-on:success="createOperation"
  />
</template>

<script>
import WYSIWYG from "../inputs/WYSIWYG";
const bootstrap = require("bootstrap");
import http from "@/modules/http";
import { useStore } from "vuex";
import draggable from "vuedraggable";
import BaseIcon from "../icons/BaseIcon";
import Videos from "@/components/inputs/Videos";
import ConfirmModal from "./ConfirmModal.vue";

export default {
  name: "ManufacturingOperationsModal",
  props: {
    modelValue: Array,
    id: Number,
    lock: Boolean,
  },
  components: { ConfirmModal, BaseIcon, WYSIWYG, draggable, Videos },
  data() {
    return {
      dragOptions: {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      },
      createObject: null,
      scrollTop: 0,
      editItem: null,
      store: useStore(),
      myModal: undefined,
      operationSteps: [{ id: null }],
      showVideo: false,
      editVideoData: null,
      lockData: this.lock,
      columnNumbers: [
        this.$t("serialNumbers.first"),
        this.$t("serialNumbers.second"),
        this.$t("serialNumbers.third"),
        this.$t("serialNumbers.fourth"),
        this.$t("serialNumbers.fifth"),
        this.$t("serialNumbers.sixth"),
        this.$t("serialNumbers.seventh"),
        this.$t("serialNumbers.eighth"),
        this.$t("serialNumbers.ninth"),
        this.$t("serialNumbers.tenth"),
      ],
    };
  },
  computed: {
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
    operations() {
      return this.store.state.operations;
    },
  },
  methods: {
    ordinal_suffix_of(i, asText = false) {
      if (i > 0 && this.operationSteps[i]?.parallel) {
        return this.ordinal_suffix_of(i - 1);
      }
      if (asText && i < 10) {
        return this.columnNumbers[i];
      }
      i++;
      if (this.$i18n.locale !== "en") {
        return i + ".";
      }
      let j = i % 10,
        k = i % 100;
      if (j === 1 && k !== 11) {
        return i + "st";
      }
      if (j === 2 && k !== 12) {
        return i + "nd";
      }
      if (j === 3 && k !== 13) {
        return i + "rd";
      }
      return i + "th";
    },
    showModal() {
      this.operationSteps = [];
      for (const val of this.modelValue) {
        this.operationSteps.push({
          id: val.id,
          parallel: val.parallel ?? val.pivot?.parallel,
          time: val.time ?? val.pivot.time,
          description: val.description ?? val.pivot?.description ?? "",
          step_id: val.step_id ?? val.pivot?.id ?? null,
        });
      }
      this.operationSteps.push({ id: null });

      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.myModal.show();
    },
    createOption(v) {
      let i = this.operations.find(
        (e) => e.name.toLowerCase().indexOf(v.toLowerCase()) > -1
      );
      if (i !== null && i !== undefined) {
        return i;
      }
      return { id: 0, name: v };
    },
    createOperation() {
      http
        .fetch(`/manufacturing/operations`, { name: this.createObject.name })
        .then((data) => {
          this.createObject.id = data.id;
          this.operations.push(data);
          for (const p in this.operationSteps) {
            if (this.operationSteps[p].id === 0) {
              this.operationSteps[p].id = data.id;
            }
          }
          this.createObject = null;
        });
    },
    selectedItem(e) {
      if (e.id === 0) {
        this.createObject = e;
        this.$refs.createModal.showModal();
      }
      let hasNull = false;
      for (const p in this.operationSteps) {
        if (this.operationSteps[p].id === null) {
          if (p !== this.operationSteps.length - 1) {
            this.operationSteps.splice(p, 1);
          } else {
            hasNull = true;
          }
        }
      }
      if (!hasNull) {
        this.operationSteps.push({ id: null });
      }
    },
    editDescription(p) {
      this.scrollTop = this.$refs.modalBody.scrollTop;
      this.editItem = p;
      this.editItem.name = this.operations.find((e) => e.id === p.id)?.name;
    },
    editVideos(e) {
      this.editVideoData = e.step_id;
      this.showVideo = true;
    },
    back() {
      this.editItem = null;
      setTimeout(() => {
        this.$refs.modalBody.scrollTo(0, this.scrollTop);
      }, 80);
    },
    save() {
      let operations = [];
      for (const p of this.operationSteps) {
        if (p.id !== null) {
          if (p.step_id === undefined) {
            delete p.step_id;
          }
          if (p.description === undefined) {
            delete p.description;
          }
          p.name = this.operations.find((e) => e.id === p.id)?.name;
          operations.push(p);
        }
      }
      this.$emit("update:lock", this.lockData);
      this.$emit("update:modelValue", operations);
      this.myModal.hide();
    },
  },
  emits: ["update:modelValue", "update:lock"],
};
</script>
<style scoped>
ul {
  list-style-type: none;
}
.dragArea {
  min-height: 2px;
  margin-bottom: 2em;
}
</style>

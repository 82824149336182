<template>
  <div class="align-items-center">
    <button
      class="btn btn-secondary btn-sm"
      :class="lock ? ' bg-success text-success text-white' : ''"
      v-on:click="showModal()"
    >
      <BaseIcon name="edit-sqare" />
    </button>
    <button class="btn btn-secondary btn-sm" v-on:click="print()">
      <BaseIcon name="printer" />
    </button>
    <span class="badge badge-outline ms-1" v-for="item in value" :key="item.id">
      {{ item.name }}
    </span>
  </div>
  <ManufacturingOperationsModal
    ref="modal"
    :id="id"
    v-model="value"
    v-model:lock="lock"
  />
</template>
<script>
import ManufacturingOperationsModal from "@/components/modals/ManufacturingOperationsModal";
import BaseIcon from "@/components/icons/BaseIcon";

export default {
  name: "ManufacturingOperations",
  components: { BaseIcon, ManufacturingOperationsModal },
  props: {
    id: Number,
    row: Object,
    modelValue: Array,
    extraRow: String,
  },
  data() {
    return {
      value: this.modelValue,
      lock: this.extraRow,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("update", this.value, false);
    },
    lock() {
      this.$emit("update:extraRow", this.lock);
    },
    extraRow() {
      this.lock = this.extraRow;
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  methods: {
    showModal() {
      this.$refs.modal.showModal();
    },
    print() {
      window.apps.callEvent("print:operations", this.id);
    },
  },
  emits: ["update:modelValue", "update", "update:extraRow"],
};
</script>
